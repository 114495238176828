<template>
  <div class="dashboard-page">
    <mega-header
      class="overlay-dark overlay-90 text-lighter"
      :title="`ARROUND ${$t('statistics')}`"
      background="https://images.pexels.com/photos/1902647/pexels-photo-1902647.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
    >
      <span class="text-muted">{{ $t("welcome") }} {{ user.email }}!</span>
    </mega-header>

    <div
      class="navbar sm-down:d-none border-bottom bg-lighter md-up:sticky-top"
      style="top: 70px;"
    >
      <div class="container py-3 sm-down:flex-column">
        <router-link
          :to="{ name: 'Round_Post' }"
          class="btn btn-sm btn-primary md-up:mr-3 text-left d-block sm-down:w-100 sm-down:mb-3"
        >
          <span>{{ $t("new_round") }}</span>
        </router-link>

        <span
          class="btn btn-sm btn-arround md-up:mr-3 text-left d-block sm-down:w-100 sm-down:mb-3"
          @click="addModel"
        >
          <span>{{ $t("new_model") }}</span>
        </span>

        <span
          class="btn btn-sm btn-arround md-up:mr-3 text-left d-block sm-down:w-100 sm-down:mb-3"
          @click="addGroup"
        >
          <span>{{ $t("new_group") }}</span>
        </span>

        <router-link
          :to="{
            name: 'Models',
            params: { roundType: '3d', is_active: 'active' }
          }"
          class="btn btn-sm btn-light text-left d-block sm-down:w-100 ml-auto"
        >
          <i class="i-package float-left"></i>
          <span>{{ $t("models") }}</span>
        </router-link>

        <router-link
          :to="{ name: 'Rounds' }"
          class="btn btn-sm btn-light text-left d-block sm-down:w-100"
        >
          <i class="i-layers float-left"></i>
          <span>{{ $t("rounds") }}</span>
        </router-link>
      </div>
    </div>

    <section>
      <div class="container py-5">
        <div class="row">
          <div class="md-down:col-12 lg-up:col-6">
            <chart
              endpoint="/stat/common/users"
              chartName="new_users"
              :doubleGraph="false"
            />
          </div>
          <div class="md-down:col-12 lg-up:col-6">
            <chart
              endpoint="/stat/common/rounds"
              chartName="rounds"
              :doubleGraph="false"
            />
          </div>
        </div>
        <div class="row">
          <div class="md-down:col-12 lg-up:col-6">
            <chart
              :endpoint="[
                ['/stat/common/users', 'iOS', 'device', 'ios'],
                ['/stat/common/users', 'Android', 'device', 'android']
              ]"
              chartName="registrations"
              :multiGraph="true"
            />
          </div>

          <div class="md-down:col-12 lg-up:col-6">
            <chart
              :endpoint="[
                ['/stat/share/round', 'round'],
                ['/stat/share/model', 'model_title']
              ]"
              chartName="shares"
              :multiGraph="true"
            />
          </div>
        </div>

        <div class="row">
          <div class="md-down:col-12 lg-up:col-6">
            <chart
              endpoint="/stat/arp/recognitions"
              chartName="ref_detects"
              :doubleGraph="false"
            />
          </div>

          <div class="md-down:col-12 lg-up:col-6">
            <chart
              endpoint="/stat/arp/transitions"
              chartName="arr_visits"
              :doubleGraph="false"
            />
          </div>
        </div>

        <div class="row">
          <div class="md-down:col-12 lg-up:col-6">
            <chart
              endpoint="/stat/common/photo"
              chartName="photo_make_clicks"
              :doubleGraph="true"
              firstChartLineName="install_mode"
              secondChartLineName="around_mode"
            />
          </div>
          <div class="md-down:col-12 lg-up:col-6">
            <chart
              endpoint="/stat/common/video"
              chartName="video_record_clicks"
              :doubleGraph="true"
              firstChartLineName="install_mode"
              secondChartLineName="around_mode"
            />
          </div>
        </div>

        <div class="row">
          <div class="md-down:col-12 lg-up:col-6">
            <chart
              :endpoint="[
                ['/stat/user/roundviews', 'Users'],
                ['/stat/arp/roundviews', 'Business']
              ]"
              chartName="views_from_user_and_business"
              :multiGraph="true"
            />
          </div>

          <div class="md-down:col-12 lg-up:col-6">
            <chart
              :endpoint="[
                ['/stat/common/modificationcount', 'modif_count'],
                ['/stat/common/modificationweight', 'modif_weight']
              ]"
              chartName="modifications"
              :multiGraph="true"
            />
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container pb-5">
        <div class="d-flex mb-4">
          <h4 class="bold text-muted text-dark mb-0">
            {{ $t("last_models") }}
          </h4>
          <router-link
            class="btn btn-sm ml-auto"
            :to="{
              name: 'Models',
              params: { roundType: '3d', is_active: 'active' }
            }"
            >{{ $t("view_all") }}</router-link
          >
        </div>

        <no-ssr>
          <mega-slider class="row" :options="slider">
            <mega-slide v-for="(model, i) in models" :key="i">
              <div class="px-3">
                <model-card class="cursor-grab" :data="model" />
              </div>
            </mega-slide>
          </mega-slider>
        </no-ssr>
      </div>
    </section>

    <section>
      <div class="container pb-5">
        <div class="d-flex mb-4">
          <h4 class="bold text-muted text-dark mb-0">
            {{ $t("last_rounds") }}
          </h4>
          <router-link class="btn btn-sm ml-auto" :to="{ name: 'Rounds' }">{{
            $t("view_all")
          }}</router-link>
        </div>

        <no-ssr>
          <mega-slider class="row" :options="slider">
            <mega-slide v-for="(round, i) in rounds" :key="i">
              <div class="px-3">
                <round-card class="cursor-grab" :data="round" />
              </div>
            </mega-slide>
          </mega-slider>
        </no-ssr>
      </div>
    </section>

    <new-group ref="new-group" />
    <new-model ref="new-model" />
  </div>
</template>

<script>
import NewGroup from "../../components/new_group";
import NewModel from "../../components/new_model";
import ModelCard from "../Models/components/model-card";
import RoundCard from "../Rounds/components/round-card";
import locale from "element-ui/lib/locale";
import "element-ui/lib/theme-chalk/button.css";
import ru from "element-ui/lib/locale/lang/ru-RU";
import en from "element-ui/lib/locale/lang/en";
import Chart from "../../components/Chart";

export default {
  name: "Statistics",
  data() {
    const language = localStorage.getItem("language") || "en";

    return {
      baseChartOptions: {
        chart: {
          height: "300px",
          selection: { enabled: false },
          zoom: { enabled: false },
          toolbar: { show: false }
        }
      },
      locale: language == "ru" ? "ru-RU" : "en-US",
      slider: {
        slidesPerView: 4,
        breakpoints: {
          750: { slidesPerView: 1 },
          990: { slidesPerView: 2 },
          1470: { slidesPerView: 3 }
        }
      },
      models: [],
      rounds: [],
      refresh: null,
      year: this.$u2d(new Date(), "YYYY")
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    locale.use(this.$i18n.locale === "ru" ? ru : en);

    this.render();
    this.$navbar.name = this.$t("statistics");
  },

  methods: {
    addModel() {
      this.$refs["new-model"].open();
    },
    addGroup() {
      this.$refs["new-group"].open();
    },

    render() {
      this.$nextTick(() => {
        setTimeout(() => {
          Promise.all([this.getRounds(), this.getModels()]);
        }, 0);
      });
    },

    queueRequest(queue) {
      const run = () => {
        let req = queue.shift();
        req().then(() => {
          if (queue.length > 0) setTimeout(run);
        });
      };

      return run;
    },

    countAndGroupByMonth(data) {
      const obj = Object.assign({}, this.monthsy);
      data.map(item => {
        let month = new Date(item.timestamp).toLocaleString(this.locale, {
          month: "short"
        });
        obj[month] += 1;
      });

      return obj;
    },

    getModels() {
      return this.$api.v2
        .get("/models", {
          params: {
            limit: 4,
            sn_id: this.$store.getters.selectedSnId
          }
        })
        .then(response => {
          this.models = [];
          let models = response.data.models;

          let i = 0,
            j = 0;
          while (j < 4) {
            if (models[i].modifications && models[i].modifications.length > 0) {
              this.models.push(models[i]);
              j++;
            }

            i++;
          }

          this.count.models = response.data.count;
        });
    },

    getRounds() {
      let params = {
        limit: 6,
        is_location_hidden: true,
        sn_id: this.$store.getters.selectedSnId
      };

      return this.$api.v2.get("/rounds", { params }).then(response => {
        const rounds = response.data.rounds;

        this.rounds = rounds.slice(0, 6);

        this.count.rounds = response.data.count;
      });
    }
  },
  components: {
    NewGroup,
    NewModel,
    RoundCard,
    ModelCard,
    Chart
  }
};
</script>
