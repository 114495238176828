var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dashboard-page" },
    [
      _c(
        "mega-header",
        {
          staticClass: "overlay-dark overlay-90 text-lighter",
          attrs: {
            title: `ARROUND ${_vm.$t("statistics")}`,
            background:
              "https://images.pexels.com/photos/1902647/pexels-photo-1902647.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
          }
        },
        [
          _c("span", { staticClass: "text-muted" }, [
            _vm._v(
              _vm._s(_vm.$t("welcome")) + " " + _vm._s(_vm.user.email) + "!"
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "navbar sm-down:d-none border-bottom bg-lighter md-up:sticky-top",
          staticStyle: { top: "70px" }
        },
        [
          _c(
            "div",
            { staticClass: "container py-3 sm-down:flex-column" },
            [
              _c(
                "router-link",
                {
                  staticClass:
                    "btn btn-sm btn-primary md-up:mr-3 text-left d-block sm-down:w-100 sm-down:mb-3",
                  attrs: { to: { name: "Round_Post" } }
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("new_round")))])]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass:
                    "btn btn-sm btn-arround md-up:mr-3 text-left d-block sm-down:w-100 sm-down:mb-3",
                  on: { click: _vm.addModel }
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("new_model")))])]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass:
                    "btn btn-sm btn-arround md-up:mr-3 text-left d-block sm-down:w-100 sm-down:mb-3",
                  on: { click: _vm.addGroup }
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("new_group")))])]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass:
                    "btn btn-sm btn-light text-left d-block sm-down:w-100 ml-auto",
                  attrs: {
                    to: {
                      name: "Models",
                      params: { roundType: "3d", is_active: "active" }
                    }
                  }
                },
                [
                  _c("i", { staticClass: "i-package float-left" }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("models")))])
                ]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass:
                    "btn btn-sm btn-light text-left d-block sm-down:w-100",
                  attrs: { to: { name: "Rounds" } }
                },
                [
                  _c("i", { staticClass: "i-layers float-left" }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("rounds")))])
                ]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("section", [
        _c("div", { staticClass: "container py-5" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "md-down:col-12 lg-up:col-6" },
              [
                _c("chart", {
                  attrs: {
                    endpoint: "/stat/common/users",
                    chartName: "new_users",
                    doubleGraph: false
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "md-down:col-12 lg-up:col-6" },
              [
                _c("chart", {
                  attrs: {
                    endpoint: "/stat/common/rounds",
                    chartName: "rounds",
                    doubleGraph: false
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "md-down:col-12 lg-up:col-6" },
              [
                _c("chart", {
                  attrs: {
                    endpoint: [
                      ["/stat/common/users", "iOS", "device", "ios"],
                      ["/stat/common/users", "Android", "device", "android"]
                    ],
                    chartName: "registrations",
                    multiGraph: true
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "md-down:col-12 lg-up:col-6" },
              [
                _c("chart", {
                  attrs: {
                    endpoint: [
                      ["/stat/share/round", "round"],
                      ["/stat/share/model", "model_title"]
                    ],
                    chartName: "shares",
                    multiGraph: true
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "md-down:col-12 lg-up:col-6" },
              [
                _c("chart", {
                  attrs: {
                    endpoint: "/stat/arp/recognitions",
                    chartName: "ref_detects",
                    doubleGraph: false
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "md-down:col-12 lg-up:col-6" },
              [
                _c("chart", {
                  attrs: {
                    endpoint: "/stat/arp/transitions",
                    chartName: "arr_visits",
                    doubleGraph: false
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "md-down:col-12 lg-up:col-6" },
              [
                _c("chart", {
                  attrs: {
                    endpoint: "/stat/common/photo",
                    chartName: "photo_make_clicks",
                    doubleGraph: true,
                    firstChartLineName: "install_mode",
                    secondChartLineName: "around_mode"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "md-down:col-12 lg-up:col-6" },
              [
                _c("chart", {
                  attrs: {
                    endpoint: "/stat/common/video",
                    chartName: "video_record_clicks",
                    doubleGraph: true,
                    firstChartLineName: "install_mode",
                    secondChartLineName: "around_mode"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "md-down:col-12 lg-up:col-6" },
              [
                _c("chart", {
                  attrs: {
                    endpoint: [
                      ["/stat/user/roundviews", "Users"],
                      ["/stat/arp/roundviews", "Business"]
                    ],
                    chartName: "views_from_user_and_business",
                    multiGraph: true
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "md-down:col-12 lg-up:col-6" },
              [
                _c("chart", {
                  attrs: {
                    endpoint: [
                      ["/stat/common/modificationcount", "modif_count"],
                      ["/stat/common/modificationweight", "modif_weight"]
                    ],
                    chartName: "modifications",
                    multiGraph: true
                  }
                })
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("section", [
        _c(
          "div",
          { staticClass: "container pb-5" },
          [
            _c(
              "div",
              { staticClass: "d-flex mb-4" },
              [
                _c("h4", { staticClass: "bold text-muted text-dark mb-0" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("last_models")) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-sm ml-auto",
                    attrs: {
                      to: {
                        name: "Models",
                        params: { roundType: "3d", is_active: "active" }
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("view_all")))]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "no-ssr",
              [
                _c(
                  "mega-slider",
                  { staticClass: "row", attrs: { options: _vm.slider } },
                  _vm._l(_vm.models, function(model, i) {
                    return _c("mega-slide", { key: i }, [
                      _c(
                        "div",
                        { staticClass: "px-3" },
                        [
                          _c("model-card", {
                            staticClass: "cursor-grab",
                            attrs: { data: model }
                          })
                        ],
                        1
                      )
                    ])
                  }),
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("section", [
        _c(
          "div",
          { staticClass: "container pb-5" },
          [
            _c(
              "div",
              { staticClass: "d-flex mb-4" },
              [
                _c("h4", { staticClass: "bold text-muted text-dark mb-0" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("last_rounds")) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-sm ml-auto",
                    attrs: { to: { name: "Rounds" } }
                  },
                  [_vm._v(_vm._s(_vm.$t("view_all")))]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "no-ssr",
              [
                _c(
                  "mega-slider",
                  { staticClass: "row", attrs: { options: _vm.slider } },
                  _vm._l(_vm.rounds, function(round, i) {
                    return _c("mega-slide", { key: i }, [
                      _c(
                        "div",
                        { staticClass: "px-3" },
                        [
                          _c("round-card", {
                            staticClass: "cursor-grab",
                            attrs: { data: round }
                          })
                        ],
                        1
                      )
                    ])
                  }),
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("new-group", { ref: "new-group" }),
      _vm._v(" "),
      _c("new-model", { ref: "new-model" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }